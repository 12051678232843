

















import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';

import DnbButton from '@/components/DnbButton.vue';

export default Vue.extend({
  name: 'api-usage',

  components: {
    DnbButton,
    Datepicker,
  },

  data() {
    return {
      authenticated: false,
    };
  },
});
